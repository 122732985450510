import React, { useContext } from "react";
import { unflatten } from "flat";
import { useIntl, FormattedMessage } from "react-intl";
import Section from "../../Section";
import Header from "../../Header";
import Container from "../../Container";
import {
  Title,
  Icon,
  ValuesList,
  Value,
  ValueContent,
  Certificate,
  Certificates,
  CertificatesTitle,
} from "./styled";
import ImagesContext from "../../../helpers/ImagesContext";
import Anchor from "../../Anchor";
import Img from "../../Img";

const Values: React.FC = () => {
  const images = useContext(ImagesContext);
  const { messages, formatMessage } = useIntl();
  const content = unflatten(messages);

  return (
    <>
      <Anchor id="values" />
      <Section>
        <Header
          background={messages["values.header.background"] as string}
          height={420}
          title={formatMessage({ id: "values.header.title" })}
          circlePosition="25%"
          loginApply={{
            login: {
              link: messages["values.header.login.link"] as string,
              label: formatMessage({ id: "values.header.login.label" }),
            },
            apply: {
              link: messages["values.header.apply.link"] as string,
              label: formatMessage({ id: "values.header.apply.label" }),
            },
          }}
        />
        <Container dTop={8} dBottom={8} mTop={4} mBottom={4}>
          <p>
            <FormattedMessage id="values.description" />
          </p>
          <Title>
            <FormattedMessage id="values.title" />
          </Title>
          <ValuesList>
            {content.values.list.map((v, i) => {
              const icon = images.find((i) => v.image.src === i.file) || null;
              return (
                <Value key={v.title}>
                  <Icon>
                    <Img data={icon} alt={v.image.alt} />
                  </Icon>
                  <ValueContent>
                    <h3>
                      <FormattedMessage id={`values.list.${i}.title`} />
                    </h3>
                    <p>
                      <FormattedMessage
                        id={`values.list.${i}.description`}
                        values={{ br: <br /> }}
                      />
                    </p>
                  </ValueContent>
                </Value>
              );
            })}
          </ValuesList>
          <CertificatesTitle>
            <h2>
              <FormattedMessage id="values.certificates.title" />
            </h2>
          </CertificatesTitle>
          <Certificates>
            {content.values.certificates.list.map((c, i) => {
              const certificateImage =
                images.find((i) => c.image.src === i.file) || null;

              return (
                <Certificate key={i}>
                  <Img data={certificateImage} alt={c.image.alt} />
                </Certificate>
              );
            })}
          </Certificates>
        </Container>
      </Section>
    </>
  );
};

export default Values;
