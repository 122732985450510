import styled, { css } from "styled-components";

export const ServicesList = styled.div`
  width: 100%;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
      flex-wrap: wrap;
      margin: ${({ theme }) => `-${theme.spacing.unit * 2}px`};
      margin-top: ${({ theme }) => theme.spacing.unit}px;
    }
  `};
`;

export const Service = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      width: ${({ theme }) =>
        `calc(${100 / 3}% - ${theme.spacing.unit * 4}px)`};
      margin: ${({ theme }) => `${theme.spacing.unit * 2}px`};

      &:last-child {
        margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
      }
    }
  `};
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.palette.text.alternative};
  font-weight: 400;
`;

export const Platforms = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.big}px;
  background-color: ${({ theme }) => theme.palette.background.alternative};
  padding: ${({ theme }) => `${theme.spacing.unit * 4}px`};
  margin-top: ${({ theme }) => theme.spacing.unit * 8}px;
  width: 100%;
  box-sizing: border-box;

  & > h2 {
    margin: 0;
  }
`;

export const PlatformsList = styled.div`
  width: 100%;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
      flex-wrap: wrap;
      margin: ${({ theme }) => `-${theme.spacing.unit * 2}px`};
      margin-top: ${({ theme }) => theme.spacing.unit}px;
    }
  `};
`;

export const Platform = styled.div`
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};

  &:last-child {
    margin-bottom: 0;
  }

  & > h3 {
    margin: 0;
    color: ${({ theme }) => theme.palette.primary.default};
  }

  & > h4 {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
  }

  & > p {
    margin: 0;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      width: ${({ theme }) => `calc(50% - ${theme.spacing.unit * 4}px)`};
      margin: ${({ theme }) => `${theme.spacing.unit * 2}px`};

      &:last-child {
        margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
      }
    }
  `};
`;

export const Logo = styled.div`
  width: 40px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.unit * 2}px;
`;

export const ServiceContent = styled.div`
  & > h3 {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
    color: ${({ theme }) => theme.palette.primary.default};
  }

  & > p {
    margin: 0;
  }
`;
