import React from "react";
import Section from "../../Section";
import Header from "../../Header";
import { useIntl, FormattedMessage } from "react-intl";
import Container from "../../Container";
import {
  Iframe,
  Wrapper,
  Form,
  Title,
  Info,
  Separator,
  Company,
} from "./styled";
import Anchor from "../../Anchor";

const Contact: React.FC = () => {
  const { messages, formatMessage } = useIntl();

  return (
    <>
      <Anchor id="contact" />
      <Section>
        <Header
          background={messages["contact.header.background"] as string}
          height={420}
          objectPosition="center 21%"
          title={formatMessage({ id: "contact.header.title" })}
          circlePosition="45%"
          loginApply={{
            login: {
              link: messages["contact.header.login.link"] as string,
              label: formatMessage({ id: "contact.header.login.label" }),
            },
            apply: {
              link: messages["contact.header.apply.link"] as string,
              label: formatMessage({ id: "contact.header.apply.label" }),
            },
          }}
        />
        <Container dTop={8} dBottom={8} mTop={4} mBottom={4}>
          <Title>
            <FormattedMessage id="contact.title" />
          </Title>
          <Wrapper
            data-netlify="true"
            name="contact"
            method="post"
            data-netlify-honeypot="bot-field"
          >
            <Form>
              <input type="hidden" name="form-name" value="contact" />
              <input
                type="text"
                name="name"
                placeholder={formatMessage({ id: "contact.form.name" })}
                required
              />
              <input
                type="text"
                name="email"
                placeholder={formatMessage({ id: "contact.form.email" })}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder={formatMessage({ id: "contact.form.phone" })}
                required
              />
              <textarea
                name="message"
                placeholder={formatMessage({ id: "contact.form.message" })}
                required
              />
            </Form>
            <Info>
              <Company>
                <FormattedMessage id="contact.info.company" />
              </Company>
              <p>
                <FormattedMessage id="contact.info.address.street" />{" "}
                <FormattedMessage id="contact.info.address.number" />
                <br />
                <FormattedMessage id="contact.info.address.zip" />{" "}
                <FormattedMessage id="contact.info.address.city" />
                <br />
                <FormattedMessage id="contact.info.address.country" />
              </p>
              <Separator />
              <p>
                <FormattedMessage id="contact.info.open.days" />
                <br />
                <FormattedMessage id="contact.info.open.hours" />
              </p>
              <Separator />
              <p>
                <a href={formatMessage({ id: "contact.info.phone.link" })}>
                  <FormattedMessage id="contact.info.phone.text" />
                </a>
                <br />
                <a href={formatMessage({ id: "contact.info.email.link" })}>
                  <FormattedMessage id="contact.info.email.text" />
                </a>
              </p>
              <Separator />
              <p>
                <FormattedMessage id="contact.info.vat" />
              </p>
              <button type="submit">
                <FormattedMessage id="contact.form.button" />
              </button>
            </Info>
          </Wrapper>
        </Container>
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.049775225439!2d4.493797815639679!3d50.737565674516205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3d735ed173d81%3A0xd1975f5fe9ed3f8b!2sPlace%20A.%20Favresse%2047%2C%201310%20La%20Hulpe!5e0!3m2!1sen!2sbe!4v1600080408742!5m2!1sen!2sbe"
          frameBorder="0"
          allowFullScreen={false}
          aria-hidden="false"
          tabIndex={0}
        />
      </Section>
    </>
  );
};

export default Contact;
