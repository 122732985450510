import React, { useContext } from "react";
import { unflatten } from "flat";
import { useIntl, FormattedMessage } from "react-intl";
import Section from "../../Section";
import Header from "../../Header";
import Container from "../../Container";
import { Title, Testimonial, Quote } from "./styled";
import ImagesContext from "../../../helpers/ImagesContext";
import Anchor from "../../Anchor";
import Img from "../../Img";

const Clients: React.FC = () => {
  const { messages, formatMessage } = useIntl();
  const images = useContext(ImagesContext);
  const content = unflatten(messages);

  const quoteImage =
    images.find((i) => content.clients.testimonial.image.src === i.file) ||
    null;

  return (
    <>
      <Anchor id="clients" />
      <Section>
        <Header
          background={messages["clients.header.background"] as string}
          height={420}
          objectPosition="center 30%"
          title={formatMessage({ id: "clients.header.title" })}
          circlePosition="60%"
          loginApply={{
            login: {
              link: messages["clients.header.login.link"] as string,
              label: formatMessage({ id: "clients.header.login.label" }),
            },
            apply: {
              link: messages["clients.header.apply.link"] as string,
              label: formatMessage({ id: "clients.header.apply.label" }),
            },
          }}
        />
        <Container dTop={8} dBottom={8} mTop={4} mBottom={4}>
          <Title>
            <FormattedMessage id="clients.title" />
          </Title>
          <p>
            <FormattedMessage id="clients.description" />
          </p>
          <Testimonial>
            <Title>
              <FormattedMessage id="clients.testimonial.title" />
            </Title>
            <div>
              <Quote>
                <Img
                  data={quoteImage}
                  alt={content.clients.testimonial.image.alt}
                />
              </Quote>
              <p>
                <FormattedMessage id="clients.testimonial.description" />
              </p>
            </div>
          </Testimonial>
        </Container>
      </Section>
    </>
  );
};

export default Clients;
