import styled, { css } from "styled-components";
import buttonReset from "../../../helpers/buttonReset";
import linkReset from "../../../helpers/linkReset";

export const Wrapper = styled.form`
  width: 100%;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
    }
  `};
`;

export const Iframe = styled.iframe`
  height: 400px;
  width: 100%;
  border: none;
  display: block;
`;

export const Form = styled.div`
  display: block;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;

  & > input,
  & > textarea {
    /* Reset */
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;

    /* Style */
    width: 100%;
    background-color: ${({ theme }) => theme.palette.background.alternative};
    padding: ${({ theme }) => theme.spacing.unit * 2}px;
  }

  & > input {
    margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
  }

  & > textarea {
    resize: vertical;
    min-height: 200px;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      width: 50%;
      margin-bottom: 0;
      margin-right: ${({ theme }) => theme.spacing.unit * 8}px;
    }
  `};
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.default};
`;

export const Separator = styled.div`
  height: 1px;
  width: 32px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.palette.background.alternative};
  margin: ${({ theme }) => theme.spacing.unit}px 0;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > p {
    margin: 0;
  }

  & > p > a {
    ${linkReset};
    display: inline;
  }

  & > button {
    ${buttonReset};
    display: block;
    box-sizing: border-box;
    margin-top: auto;
    width: 100%;
    padding: ${({ theme }) => theme.spacing.unit * 2}px;
    background-color: ${({ theme }) => theme.palette.primary.default};
    color: ${({ theme }) => theme.palette.primary.contrast};
    text-transform: uppercase;
    font-weight: bold;
    border-radius: ${({ theme }) => theme.border.radius.medium}px;
    margin-top: ${({ theme }) => theme.spacing.unit * 4}px;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      & > button {
        max-width: 150px;
        margin-top: auto;
      }
    }
  `};
`;

export const Company = styled.p`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px!important;
`;
