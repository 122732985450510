import styled, { css } from "styled-components";

export const Title = styled.h2`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.palette.text.alternative};
  font-weight: 400;
`;

export const Icon = styled.div`
  width: 40px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.unit * 2}px;
`;

export const ValuesList = styled.div`
  width: 100%;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
      flex-wrap: wrap;
      margin: ${({ theme }) => `-${theme.spacing.unit * 2}px`};
      margin-top: ${({ theme }) => theme.spacing.unit}px;
    }
  `};
`;

export const Value = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      width: ${({ theme }) => `calc(50% - ${theme.spacing.unit * 4}px)`};
      margin: ${({ theme }) => `${theme.spacing.unit * 2}px`};

      &:last-child {
        margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
      }
    }
  `};
`;

export const ValueContent = styled.div`
  & > h3 {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
    color: ${({ theme }) => theme.palette.primary.default};
  }

  & > p {
    margin: 0;
  }
`;

export const Certificates = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: -${({ theme }) => theme.spacing.unit * 2}px;
`;

export const Certificate = styled.div`
  width: 150px;
  flex-shrink: 0;
  margin: ${({ theme }) => theme.spacing.unit * 2}px;

  img {
    width: 150px !important;
  }
`;

export const CertificatesTitle = styled.div`
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacing.unit * 8}px;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;
  padding: ${({ theme }) => theme.spacing.unit}px 0;
  box-sizing: border-box;
  border-top: ${({ theme }) =>
    `${theme.border.solid.default} ${theme.palette.primary.default}`};
  border-bottom: ${({ theme }) =>
    `${theme.border.solid.default} ${theme.palette.primary.default}`};
  width: 300px;

  & > h2 {
    margin: 0;
    text-align: center;
    color: ${({ theme }) => theme.palette.text.alternative};
    font-weight: 400;
  }
`;
