import styled from "styled-components";

export const Title = styled.h2`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.palette.text.alternative};
  font-weight: 400;
`;

export const Testimonial = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.big}px;
  background-color: ${({ theme }) => theme.palette.background.alternative};
  padding: ${({ theme }) => `${theme.spacing.unit * 4}px`};
  margin-top: ${({ theme }) => theme.spacing.unit * 8}px;
  width: 100%;
  box-sizing: border-box;

  & > h2 {
    margin: 0;
  }

  & > p {
    margin-bottom: 0;
  }
`;

export const Quote = styled.div`
  width: 64px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.unit * 2}px;
  float: left;
`;
