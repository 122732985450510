import React from "react";
import { unflatten } from "flat";
import { useIntl, FormattedMessage } from "react-intl";
import Section from "../../Section";
import Header from "../../Header";
import Container from "../../Container";
import { Wrapper, Aside, Title, List } from "./styled";
import Anchor from "../../Anchor";

const About: React.FC = () => {
  const { messages, formatMessage } = useIntl();
  const content = unflatten(messages);

  return (
    <>
      <Anchor id="about" />
      <Section>
        <Header
          background={messages["about.header.background"] as string}
          height={420}
          title={formatMessage({ id: "about.header.title" })}
          loginApply={{
            login: {
              link: messages["about.header.login.link"] as string,
              label: formatMessage({ id: "about.header.login.label" }),
            },
            apply: {
              link: messages["about.header.apply.link"] as string,
              label: formatMessage({ id: "about.header.apply.label" }),
            },
          }}
        />
        <Container dTop={8} dBottom={8} mTop={4} mBottom={4}>
          <Wrapper>
            <Aside>
              <Title>
                <FormattedMessage id="about.title" />
              </Title>
              <p>
                <FormattedMessage
                  id="about.description"
                  values={{ br: <br /> }}
                />
              </p>
            </Aside>
            <List>
              {content.about.list.map((p, i) => (
                <div key={i}>
                  <h3>
                    <FormattedMessage id={`about.list.${i}.title`} />
                  </h3>
                  <p>
                    <FormattedMessage
                      id={`about.list.${i}.description`}
                      values={{ br: <br /> }}
                    />
                  </p>
                </div>
              ))}
            </List>
          </Wrapper>
        </Container>
      </Section>
    </>
  );
};

export default About;
