import React, { useContext } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { unflatten } from "flat";
import Section from "../../Section";
import Header from "../../Header";
import Container from "../../Container";
import {
  ServicesList,
  Service,
  Title,
  Platforms,
  PlatformsList,
  Platform,
  Logo,
  ServiceContent,
} from "./styled";
import ImagesContext from "../../../helpers/ImagesContext";
import Anchor from "../../Anchor";
import Img from "../../Img";

const Services: React.FC = () => {
  const images = useContext(ImagesContext);
  const { messages, formatMessage } = useIntl();
  const content = unflatten(messages);

  return (
    <>
      <Anchor id="services" first />
      <Section>
        <Header
          background={messages["services.header.background"] as string}
          height={420}
          title={formatMessage({ id: "services.header.title" })}
          loginApply={{
            login: {
              link: messages["services.header.login.link"] as string,
              label: formatMessage({ id: "services.header.login.label" }),
            },
            apply: {
              link: messages["services.header.apply.link"] as string,
              label: formatMessage({ id: "services.header.apply.label" }),
            },
          }}
        />
        <Container dTop={8} dBottom={8} mTop={4} mBottom={4}>
          <Title style={{ marginTop: 0 }}>
            <FormattedMessage id="services.body.services.title" />
          </Title>
          <p>
            <FormattedMessage id="services.body.services.description" />
          </p>
          <ServicesList>
            {content.services.body.services.list.map(({ logo }, i) => {
              const logoData = images.find((i) => logo === i.file) || null;
              return (
                <Service key={i}>
                  <Logo>
                    <Img data={logoData} />
                  </Logo>
                  <ServiceContent>
                    <h3>
                      <FormattedMessage
                        id={`services.body.services.list.${i}.name`}
                      />
                    </h3>
                    <p>
                      <FormattedMessage
                        id={`services.body.services.list.${i}.description`}
                        values={{ br: <br /> }}
                      />
                    </p>
                  </ServiceContent>
                </Service>
              );
            })}
          </ServicesList>
          <Platforms>
            <Title>
              <FormattedMessage id="services.body.platforms.title" />
            </Title>
            <PlatformsList>
              {content.services.body.platforms.list.map((p, i) => (
                <Platform key={i}>
                  <h3>
                    <FormattedMessage
                      id={`services.body.platforms.list.${i}.title`}
                    />
                  </h3>
                  <h4>
                    <FormattedMessage
                      id={`services.body.platforms.list.${i}.subtitle`}
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id={`services.body.platforms.list.${i}.description`}
                      values={{ br: <br /> }}
                    />
                  </p>
                </Platform>
              ))}
            </PlatformsList>
          </Platforms>
          <Title>
            <FormattedMessage id="services.body.formats.title" />
          </Title>
          <p>
            <FormattedMessage
              id="services.body.formats.description"
              values={{ br: <br /> }}
            />
          </p>
        </Container>
      </Section>
    </>
  );
};

export default Services;
