import styled, { css } from "styled-components";
import linkReset from "../../../helpers/linkReset";

export const Picture = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  align-self: center;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;

  & img {
    width: 100%;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      margin: 0;
      margin-right: ${({ theme }) => theme.spacing.unit * 4}px;
    }
  `};
`;

export const Members = styled.div`
  width: 100%;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      width: ${({ theme }) => `calc(100% + ${theme.spacing.unit * 8}px)`};
      display: flex;
      flex-wrap: wrap;
      margin: -${({ theme }) => theme.spacing.unit * 4}px;
    }
  `};
`;

export const Member = styled.div`
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 4}px`};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      width: ${({ theme }) => `calc(50% - ${theme.spacing.unit * 8}px)`};
      margin: ${({ theme }) => theme.spacing.unit * 4}px;
      display: flex;

      &:last-child {
        margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;
      }
    }
  `};
`;

export const Quote = styled.div`
  font-style: italic;
  color: ${({ theme }) => theme.palette.primary.default};
  border-top: ${({ theme }) =>
    `${theme.border.solid.default} ${theme.palette.border.default}`};

  & > p {
    margin-bottom: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;

  & > div:first-child {
    margin-right: ${({ theme }) => theme.spacing.unit}px;
  }

  & > div > h3,
  & > div > p {
    margin: 0;
  }
`;

export const LinkedinLink = styled.a`
  ${linkReset};

  width: 1.4rem;
  color: ${({ theme }) => theme.palette.text.alternative};
  display: flex;
  align-items: center;

  & > svg {
    width: 100%;
  }
`;
