import React, { useContext } from "react";
import { unflatten } from "flat";
import { useIntl, FormattedMessage } from "react-intl";
import Section from "../../Section";
import Header from "../../Header";
import Container from "../../Container";
import ImagesContext from "../../../helpers/ImagesContext";
import { Picture, Member, Members, Quote, Info, LinkedinLink } from "./styled";
import LinkedinBrands from "../../SVGs/LinkedinBrands";
import Anchor from "../../Anchor";
import Img from "../../Img";

const Team: React.FC = () => {
  const { messages, formatMessage } = useIntl();
  const images = useContext(ImagesContext);
  const content = unflatten(messages);

  return (
    <>
      <Anchor id="team" />
      <Section>
        <Header
          background={messages["team.header.background"] as string}
          height={420}
          objectPosition="center 38%"
          title={formatMessage({ id: "team.header.title" })}
          circlePosition="25%"
          loginApply={{
            login: {
              link: messages["team.header.login.link"] as string,
              label: formatMessage({ id: "team.header.login.label" }),
            },
            apply: {
              link: messages["team.header.apply.link"] as string,
              label: formatMessage({ id: "team.header.apply.label" }),
            },
          }}
        />
        <Container dTop={8} dBottom={8} mTop={4} mBottom={4}>
          <Members>
            {content.team.list.map((m, i) => {
              const teamMemberImage =
                images.find((i) => m.image.src === i.file) || null;

              return (
                <Member key={i}>
                  <Picture>
                    <Img data={teamMemberImage} alt={m.image.alt} />
                  </Picture>
                  <div>
                    <Info>
                      <div>
                        <h3>
                          <FormattedMessage id={`team.list.${i}.name`} />
                        </h3>
                        <p>
                          <FormattedMessage id={`team.list.${i}.job`} />
                        </p>
                      </div>
                      <div>
                        <LinkedinLink
                          href={m.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedinBrands />
                        </LinkedinLink>
                      </div>
                    </Info>
                    <Quote>
                      <p>
                        <FormattedMessage id={`team.list.${i}.quote`} />
                      </p>
                    </Quote>
                  </div>
                </Member>
              );
            })}
          </Members>
        </Container>
      </Section>
    </>
  );
};

export default Team;
