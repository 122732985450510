import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
    }
  `};
`;

export const Aside = styled.aside`
  border-radius: ${({ theme }) => theme.border.radius.big}px;
  background-color: ${({ theme }) => theme.palette.background.alternative};
  padding: ${({ theme }) => `${theme.spacing.unit * 4}px`};
  margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;

  & > h2 {
    margin: 0;
  }

  & > p {
    margin-bottom: 0;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      width: 33%;
      margin-right: ${({ theme }) => theme.spacing.unit * 4}px;
      margin-bottom: 0;
    }
  `};
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.palette.text.alternative};
  font-weight: 400;
`;

export const List = styled.div`
  width: 100%;

  & > div {
    width: 100%;
    margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  & > div > h3 {
    margin: 0;
    color: ${({ theme }) => theme.palette.primary.default};
  }

  & > div > p {
    margin-bottom: 0;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      margin: -${({ theme }) => theme.spacing.unit * 2}px;

      & > div {
        width: ${({ theme }) => `calc(50% - ${theme.spacing.unit * 4}px)`};
        margin: ${({ theme }) => theme.spacing.unit * 2}px;
      }

      & > div:last-child {
        margin-bottom: 0;
      }
    }
  `};
`;
